import React from "react";

import Img from "../img/img";


function Main() {

  return (
    <div>

      <div className="z-0 absolute top-10 left-28  " >
      <Img />
      </div>
      <div className="z-10 absolute top-20 right-32">
        <Img/>
      </div>
      <div className="z-20 absolute bottom-16 left-44" >
        <Img/>
      </div>
    <div className="flex h-screen justify-center items-center">
        <div className="text-center z-30">
          <a className="text-2xl text-violet-600 font-semibold animate-pulse" href="https://cargocollective.com/marquitossanabria">
          MARQUITOS <br/> SANABRIA
          </a>
      </div>
    </div>
    </div>
  )
  
}

export default Main;
