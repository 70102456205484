import './App.css';
import Main from './components/main/main';



function App() {
  return (
    <Main />
  );
}

export default App;
