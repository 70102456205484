import React, { useState } from "react";

function Img() {

    let images = ["https://firebasestorage.googleapis.com/v0/b/marquitos-sanabria.appspot.com/o/img286.jpg?alt=media&token=dbb76414-0899-41f6-a6dd-03d2a6cb20b6", "https://firebasestorage.googleapis.com/v0/b/marquitos-sanabria.appspot.com/o/00990004.JPG?alt=media&token=12cac2b4-e778-465c-84b1-db79f415d038", "https://firebasestorage.googleapis.com/v0/b/marquitos-sanabria.appspot.com/o/10A_0042%20copy.jpg?alt=media&token=ab543836-7c20-4568-bf3d-d99a67d056f6", "https://firebasestorage.googleapis.com/v0/b/marquitos-sanabria.appspot.com/o/12230011.jpg?alt=media&token=1224bc7f-1d1e-4c7c-a78e-89c8caf9df15", "https://firebasestorage.googleapis.com/v0/b/marquitos-sanabria.appspot.com/o/18.JPG?alt=media&token=eecb9629-87bd-4690-95b3-3b363b60908d", "https://firebasestorage.googleapis.com/v0/b/marquitos-sanabria.appspot.com/o/656610.jpg?alt=media&token=8880af40-a22e-43bc-bc44-514796ac9eaf", "https://firebasestorage.googleapis.com/v0/b/marquitos-sanabria.appspot.com/o/a.JPG?alt=media&token=b87fe2c2-c9b6-4b45-bd97-034e0c1a9bb1"]
    const [displayedImage, setdisplayedImage] = useState(images[getRandomInt(images.length)]);
    

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

    function pickRandomImage() {
        let index = getRandomInt(images.length)
        setdisplayedImage(images[index])
    }
    


    return (
        <div>
            <img className="object-scale-down h-64 w-48" src={displayedImage} alt=""  onMouseOver={pickRandomImage} onMouseEnter={pickRandomImage} onClick={pickRandomImage}></img>
        </div>
    )
}

export default Img;
